import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../../../image/svg/arrowBlue.svg';
import useAppSelector from '../../../hooks/redux';
import useDebounceString from '../../../hooks/debounceString';
import { useGetDistrictsListQuery } from '../../../store/directories/directories.api';
import { IDistrictType } from '../../../models/models';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { ListsState } from '../../../store/lists/lists.slice';
import useActions from '../../../store/actions';

const storeKey: keyof ListsState = 'users';
const filterKey = 'district_id';
const searchKey = 'district_id__search';

const UserFilterDistrictSelect = () => {
    const regionId = useAppSelector((state) => state.list[storeKey].filters.region_id);
    const filterStoreValue = useAppSelector((state) => state.list[storeKey].filters[filterKey]);
    const search = useAppSelector((state) => state.list[storeKey].search[searchKey]);
    const { setFilters, deleteFilter, setSearchValue } = useActions();

    const [inputValue, setInputValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const debouncedValue = useDebounceString(search, 500);

    const { data } = useGetDistrictsListQuery({ search: debouncedValue, region_id: regionId ?? '' });

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setInputValue('');
        deleteFilter({ storeKey, filterKey });
        setSearchValue({ storeKey, key: searchKey, value: '' });
    }, [regionId]);

    useEffect(() => {
        if (!filterStoreValue) {
            setInputValue('');
        }
        if (data) {
            data?.data?.forEach((item) => {
                if (String(item.district_id) === filterStoreValue) {
                    setInputValue(item.district_name);
                    setFilters({ storeKey, filterKey, filterValue: String(item.district_id) });
                }
            });
        }
    }, [filterStoreValue, data]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchValue({ storeKey, key: searchKey, value });
    };

    const onSelectItem = (item: IDistrictType) => {
        setIsOpen(false);
        setInputValue(item.district_name);
        setFilters({ storeKey, filterKey, filterValue: String(item.district_id) });
    };

    const filterItems = data?.data;

    const closeHandler = () => setIsOpen(false);
    useOnClickOutside(ref, closeHandler);

    return (
        <div ref={ref} className='w-full mb-6 relative'>
            <div
                role='presentation'
                className={`select-container relative ${
                    isOpen ? 'rounded-t-lg border-blue' : 'rounded-lg border-borderGray'
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <input
                    type='text'
                    value={inputValue}
                    readOnly
                    // onChange={(e) => onChangeSelect(e)}
                    className='w-full h-full py-[10px] px-[12px] rounded-lg outline-0 font-bold'
                    placeholder={'Район'}
                />
                <Arrow className={`w-[20px] h-[20px] duration-200 ${isOpen ? 'rotate-[90deg]' : 'rotate-[-90deg]'}`} />
            </div>
            {isOpen ? (
                <div className='select-items z-[1000]'>
                    <div className='border-b border-borderGray bg-white sticky top-0 px-[12px] text-sm h-[32px] flex justify-between items-center text-grayDisabled'>
                        <span className='text-black w-15'>Поиск:</span>
                        <input
                            type='text'
                            value={search}
                            onChange={(e) => onSearch(e)}
                            className='grow h-full pl-2 py-[6px]  outline-0  text-gray'
                        />
                    </div>

                    {filterItems?.length ? (
                        filterItems.map((item) => (
                            <div
                                role='presentation'
                                key={item.district_id}
                                className={`${
                                    String(item.district_id) === filterStoreValue
                                        ? 'div-arrow bg-blue text-white'
                                        : ' div-arrow text-black'
                                } select-item`}
                                onClick={() => {
                                    onSelectItem(item);
                                }}
                            >
                                {item.district_name}
                            </div>
                        ))
                    ) : (
                        <div className='text-sm h-[32px] flex justify-center items-center text-grayDisabled'>
                            Ничего не найдено
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default UserFilterDistrictSelect;
