import React from 'react';

import Input from '../Inputs/Input';
import UserDownloadPhoto from './UserPhoto/UserDownloadPhoto';
import ProfileStatusContainer from './ProfileStatusContainer';
import ProfileRoleContainer from './ProfileRoleContainer';
import useAppSelector from '../../hooks/redux';
import UserPhoto from './UserPhoto/UserPhoto';
import ProfileRoles from './ProfileRoles';
import ProfileStatus from "./ProfileStatus";
import {ROLES} from '../../utils/dictionary';

const ProfilePersonInfo = () => {
    const dateCreated = useAppSelector((state) => state.users.currentUser.user_date_created);
    const currentUserId = useAppSelector((state) => state.users.currentUser.user_id);
    const authId = useAppSelector((state) => state.auth.user.user_id);
    const rolesId = useAppSelector((state) => state.users.currentUser.user_roles_list);
    const currentStatus = useAppSelector((state) => state.users.currentUser.user_status);
    const userRolesList = useAppSelector((state) => state.auth.user.user_roles_list);

    return (
        <>
            <h2 className='text-base text-gray mb-7'>Профиль</h2>
            {currentUserId === Number(authId) || userRolesList.includes(ROLES.Admin) ? <UserDownloadPhoto /> : <UserPhoto />}
            {userRolesList.includes(ROLES.Admin) ? <ProfileStatusContainer /> : <ProfileStatus status={currentStatus} />}
            {userRolesList.includes(ROLES.Admin) ? <ProfileRoleContainer /> : <ProfileRoles roles={rolesId} />}
            <Input label={'Дата Регистрации'} type={'text'} storeKey={'date_reg'} value={dateCreated} disabled />
        </>
    );
};

export default ProfilePersonInfo;
